import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useDentalPlan() {
  // Use toast
  const toast = useToast()

  const refTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'code',
      label: 'Código',
      tdClass: 'codeTdClass',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nome',
      formatter: title,
      sortable: true,
    },
    {
      key: 'ans',
      label: 'ANS',
      tdClass: 'ansTdClass',
      sortable: true,
    },
    {
      key: 'dental_plans',
      label: 'Plano(s) Dental(is)',
      tdClass: 'dentalPlansTdClass',
      sortable: false,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-health-insurance/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        showOverlay.value = false
        const { data, total } = response.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  const save = async data => {
    try {
      if (data.id) await store.dispatch('store-health-insurance/update', data)
      else await store.dispatch('store-health-insurance/add', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const deleteData = async data => {
    try {
      await store.dispatch('store-health-insurance/delete', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    fetch,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    showOverlay,

    refetchData,
    save,
    deleteData,
    catchErr,
  }
}
