<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-modal
        id="handle-modal"
        v-model="modalHandleShow"
        centered
        size="lg"
        title="Convênio"
        @hidden="resetForm"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <b-form class="pl-2 pr-2 pt-2">
            <b-row>

              <!-- Field: Code -->
              <b-col
                cols="12"
                md="2"
              >
                <validation-provider
                  #default="validationContext"
                  name="Código"
                  rules="required"
                  immediate
                >

                  <b-form-group
                    label="Código"
                    label-for="code"
                  >
                    <b-form-input
                      id="code"
                      v-model="healthInsurance.code"
                      autofocus
                      :state="getValidationState(validationContext) ? null : false"
                      trim
                    />
                    <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

              </b-col>

              <b-col
                cols="12"
                md="8"
              >
                <validation-provider
                  #default="validationContext"
                  name="Nome"
                  rules="required"
                  immediate
                >
                  <b-form-group
                    label="Nome"
                    label-for="name-id"
                  >
                    <b-form-input
                      id="name-id"
                      v-model="healthInsurance.name"
                      :state="getValidationState(validationContext) ? null : false"
                      :disabled="healthInsurance.name === 'Particular'"
                      trim
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="ANS"
                  label-for="ans-id"
                >
                  <b-form-input
                    id="ans-id"
                    v-model="healthInsurance.ans"
                    trim
                  />
                  <b-form-invalid-feedback>Obrigatório</b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Planos"
                  label-for="dental-plans-id"
                >
                  <v-select
                    id="dental-plans-id"
                    v-model="healthInsurance.dentalPlans"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="val => val.value"
                    :clearable="true"
                    :options="dentalPlans"
                    placeholder="Selecione"
                    multiple
                  >
                    <template #no-options="{}">
                      Nenhum resultado encontrado.
                    </template>
                  </v-select>

                </b-form-group>
              </b-col>

            </b-row>
          </b-form>

        </b-card>
        <template #modal-footer="{ cancel }">
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="$emit('save', healthInsurance); modalHandleShow = false"
          >
            Salvar
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel()"
          >
            Cancelar
          </b-button>
        </template>

      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BCard, BForm, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, computed, onUnmounted } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import dentalPlanStoreModule from '@/views/apps/dental-plans/useStoreDentalPlans'

import store from '@/store'
import useHealthInsurance from '../useHealthInsurance'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },

  setup() {
    localize('pt_BR') // vee-validate messages

    const USER_APP_STORE_MODULE_NAME = 'store-dental-plan'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, dentalPlanStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('store-dental-plan/fetch')
    const dentalPlans = computed(() => store.getters['store-dental-plan/forSelect']) // .filter(el => el.label !== 'Particular')

    const modalHandleShow = ref(false)

    const blankHealthInsurance = {
      id: null,
      code: null,
      name: null,
      ans: null,
      dentalPlans: [],
    }
    const healthInsurance = ref(JSON.parse(JSON.stringify(blankHealthInsurance)))
    const resetFormData = () => {
      healthInsurance.value = JSON.parse(JSON.stringify(blankHealthInsurance))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    const fillData = data => {
      healthInsurance.value = data
    }

    const {
      refetchData,
      deleteData,
      hidden,
      modalAdjustPricesShow,

    } = useHealthInsurance()

    return {
      required,
      modalHandleShow,
      modalAdjustPricesShow,
      hidden,

      refetchData,
      deleteData,
      getValidationState,
      resetForm,
      refFormObserver,
      dentalPlans,
      fillData,
      healthInsurance,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .selectAllTdClass {
    width: 50px;
  }
  .codeTdClass {
    width: 206px;
  }
  .unitTdClass {
    width: 140px;
  }
  .priceTdClass {
    width: 206px;
  }
</style>
